import styled from "styled-components"

const PageWrapper = styled.div`
  padding: 4rem 3rem;
  margin: 0 auto;
  width: 100%;
  background-color: ${props => props.theme.colors.accent1};
  border-radius: 24px;
  min-height: 60vh;
  box-shadow: 0 0.8px 2.1px rgba(0, 0, 0, 0.1),
    0 2.7px 6.9px rgba(0, 0, 0, 0.15), 0 12px 31px rgba(0, 0, 0, 0.23);

  @media screen and (max-width: 40rem) {
    padding: 1rem 0;
  }
`

export default PageWrapper
