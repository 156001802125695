import React from "react"
import styled from "styled-components"
import { Text, Button } from "deskie-ui"
import { graphql, useStaticQuery } from "gatsby"
import Img, { FluidObject } from "gatsby-image"
import Container from "./Container"

const StyledPlanMeeting = styled.div`
  box-shadow: ${props => props.theme.elevation[2]};
  background: #ffffff;
  border-radius: 1.5rem;
  padding: 2rem;
  display: flex;
  align-items: center;
  margin-top: 3rem;
  margin-bottom: 3rem;

  .gatsby-image-wrapper {
    width: 120px;
    border-radius: 1.5rem;
    flex-shrink: 0;
  }

  .content {
    width: 100%;
    max-width: 30rem;
    padding: 0 2rem;

    p {
      color: ${props => props.theme.colors.accent9};
    }
  }

  .button {
    text-decoration: none;
    margin-left: auto;
    flex-shrink: 0;
  }

  @media screen and (max-width: 56rem) {
    flex-direction: column;
    justify-content: center;
    text-align: center;

    .button {
      margin-left: initial;
    }

    .content {
      padding: 1.5rem 0;
    }
  }
`

type Data = {
  image: {
    childImageSharp: {
      fluid: FluidObject
    }
  }
}

function PlanMeeting() {
  const { image } = useStaticQuery<Data>(graphql`
    query PlanMeetingQuery {
      image: file(relativePath: { eq: "assets/anne-bredewout.png" }) {
        childImageSharp {
          fluid(maxWidth: 400) {
            ...GatsbyImageSharpFluid
          }
        }
      }
    }
  `)
  return (
    <Container>
      <StyledPlanMeeting>
        <Img
          fluid={image.childImageSharp.fluid}
          alt="Plan een meeting met Anne"
        />
        <div className="content">
          <Text h1 as="h3">
            Plan een meeting met Anne.
          </Text>
          <Text p>
            Interesse in Deskie en benieuwd hoe Deskie binnen jouw bedrijf
            past? Plan een meeting in met Anne!
          </Text>
        </div>
        <Button
          // @ts-ignore
          as="a"
          target="_blank"
          rel="noopener noreferrer"
          href="https://meetings.hubspot.com/anne-bredewout"
        >
          Plan een meeting
        </Button>
      </StyledPlanMeeting>
    </Container>
  )
}

export default PlanMeeting
